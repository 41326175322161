<template>
  <div class="flex">
    <div
      :style="{
        width: width,
        height: height,
        minWidth: width,
        minHeight: height
      }"
      class="rounded-full overflow-hidden"
    >
      <img :src="src" @error="isError = true" :key="isError" v-if="!isError" />
      <div
        v-else
        class="bg-primary-default h-full w-full grid place-items-center text-center text-neutral-white font-weight-bold"
      >
        {{ initials ? initialsName : name }}
      </div>
    </div>
    <div class="px-3" v-if="hasDetail">
      <h3 class="text-lg">{{ name }}</h3>
      <div><slot></slot></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: '4rem'
    },
    height: {
      type: String,
      default: '4rem'
    },
    src: {
      type: String,
      default: ''
    },
    hasDetail: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    initials: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isError: false
    }
  },
  computed: {
    initialsName() {
      const name = this.name?.split(' ')
      let initial = this.name?.charAt(0)
      if (name?.length > 1) {
        initial = name[0]?.charAt(0) + name?.[name?.length - 1]?.charAt(0)
      }

      return initial
    }
  }
}
</script>
